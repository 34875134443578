import store from "@/store";
import { ERROR_UPLOAD_FILE_TYPE_IMAGE } from "../constants";

export const uploadImageFile = async (file: File, variable: string) => {
  if (!(file.type.includes("jpeg") || file.type.includes("png") || file.type.includes("webp"))) {
    store.commit("createNotification", { status: 'error', message: ERROR_UPLOAD_FILE_TYPE_IMAGE });
    return variable;
  }
  
  return await getImageLink(file) as string;
}

export function getImageLink(file: File) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const fr = new FileReader();
      fr.onload = (() => {
        return (e: any) => resolve(e.target.result);
      })();
      fr.readAsDataURL(file);
    }
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  })
}

export const getCorrectFileSize = (size: number) => {
  const sizes = [
    { id: 'КБ', value: 1024 },
    { id: 'МБ', value: 1024 * 1024 },
    { id: 'ГБ', value: 1024 * 1024 * 1024 },
  ];
  for (const sizetype of sizes) {
    if (size / sizetype.value < 1000) return `${Math.ceil10(size / sizetype.value, -1)} ${sizetype.id}`;
  }
  return size;
}

export const checkImageFiles = (files: FileList) => {
  for (const file of files) {
    if (!checkImageFile(file, true)) return false;
  }
  return true;
}

export const checkImageFile = (file: File, show = false) => {
  if (!(file.type.includes("jpeg") || file.type.includes("png") || file.type.includes("webp"))) {
    if (show) store.commit("createNotification", { status: 'error', message: ERROR_UPLOAD_FILE_TYPE_IMAGE });
    return false;
  }
  return true;
} 

export const checkFileSize = (files: FileList) => {
  for (const file of files) {
    if (file.size > 10485760) {
      store.commit("createNotification", {
        status: "error",
        message: `Файл "${file.name}" превышает допустимый размер файла (50 мб)!`,
      });
      return false;
    }
  }
  return true;
}

export const currentImageSize = (src: string, width: number, height: number) => {
  const split = src.split('.');
  const type = split.pop();
  return split.join('.') + `-${width}x${height}.` + type;
}