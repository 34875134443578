import { dynamicsObject } from "@/interfaces";
import { Modal } from "@/interfaces/modal/modal.dto";

export function commonCheckInputs(inputs: Array<Modal.Input>, data: dynamicsObject) {
  for (const key in data) {
    const input = inputs.find(i => i.id === key);
    if (!input) continue;
    if (input.drop && !data[key + '_id'] && input.required && !input.uncheck) {
      input.error = true;
      return { status: false, input, showMessage: true };
    }
    let value = data[key];
    if (input.type === 'number') value = Number(value);
    if (!value && value !== 0 && input.required) {
      input.error = true;
      return { status: false, input };
    } else {
      input.error = false;
    }
  }
  return { status: true };
}