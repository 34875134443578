
import { defineComponent, PropType } from "vue-demi";
import { User } from "@/interfaces/user/user.dto";
import { SubscriberIcon, PenIcon } from '@/plugins/icons';
import { checkFileSize, checkImageFiles, currentImageSize } from '@/utils/files';
import { PROFILE_ACCESS_INPUTS, PROFILE_COMMON_INPUTS } from "@/store/common/user";
import { useAPI } from "@/use";
import CommonInputs from '@/components/common/inputs/index.vue';
import ProfileNotification from '../notification/index.vue';

export default defineComponent({
  name: "Profile",
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    }
  },
  data() {
    return {
      info: PROFILE_COMMON_INPUTS(this.user),
      access: PROFILE_ACCESS_INPUTS(this.user),
    }
  },
  methods: {
    currentImageSize,
    async uploadAvatar() {
      const input = this.$refs.file as HTMLInputElement;
      if (!input || !input.files) return;
      if (!checkFileSize(input.files) || !checkImageFiles(input.files)) return;

      const formData = new FormData();
      formData.append("file", input.files[0]);

      await useAPI().common.file.updateAvatarUserMethod(formData);
    },
    async submit() {
      await useAPI().user.updateMethod({...this.info.data, notification: this.user.notification});
    },
    async submitAccessCode() {
      const request = await useAPI().user.updateAccessCodeMethod();
      this.user.accessCode = request.data.accessCode;
      if (request.data.accessCode)
        this.access.data.accessCode = `${process.env.VUE_APP_URL}/auth/access/${request.data.accessCode}`;
    }
  },
  components: {
    ProfileNotification,
    SubscriberIcon,
    PenIcon,
    CommonInputs,
  }
});
