
import { defineComponent, PropType } from "vue-demi";
import { User } from "@/interfaces/user/user.dto";

export default defineComponent({
  name: "ProfileNotification",
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true,
    },
  },
  data() {
    return {
      notifications: [
        {
          id: "knocked",
          name: 'Получать сообщения от бота о новых подписчиках со статусом "Постучался"',
        },
      ],
    };
  },
});
