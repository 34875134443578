
import { defineComponent } from "vue-demi";
import ProfileMain from "@/components/profile/main/index.vue";

export default defineComponent({
  name: "Profile",
  components: {
    ProfileMain,
  },
});
