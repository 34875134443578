import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c82f2e22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container flex-column" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = {
  key: 0,
  class: "action"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_input = _resolveComponent("common-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputs, (input) => {
        return (_openBlock(), _createBlock(_component_common_input, {
          key: input.id,
          input: input,
          data: _ctx.data,
          onInputHandler: _ctx.submitAction,
          onToggleDrop: _ctx.toggleDrop,
          onInputKeyUp: _ctx.inputKeyUp,
          onSelectDropElement: _ctx.selectDropElement,
          onToggleSwitcher: _ctx.toggleSwitcher
        }, null, 8, ["input", "data", "onInputHandler", "onToggleDrop", "onInputKeyUp", "onSelectDropElement", "onToggleSwitcher"]))
      }), 128))
    ]),
    (_ctx.submit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "btn btn--main w100",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitAction && _ctx.submitAction(...args)))
          }, _toDisplayString(_ctx.submit.name), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}