import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13fc51ad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
      return (_openBlock(), _createElementBlock("label", {
        class: "notification flex items-center",
        key: notification.id
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          "onUpdate:modelValue": ($event: any) => ((_ctx.user.notification[notification.id]) = $event)
        }, null, 8, _hoisted_2), [
          [_vModelCheckbox, _ctx.user.notification[notification.id]]
        ]),
        _createElementVNode("span", null, _toDisplayString(notification.name), 1)
      ]))
    }), 128))
  ]))
}