import { markRaw } from "vue-demi";
import { dynamicsObject } from "@/interfaces";
import { PaymentDetails } from "@/interfaces/payment/payment.dto";
import { User } from "@/interfaces/user/user.dto";
import { BotpayIcon, ProdamusIcon, RobokassaIcon, TinkoffIcon, YoomoneyIcon } from "@/plugins/icons";

// export const AUTH_INPUTS = {
//   inputs: [
//     { required: true, show: true, id: 'email', name: '', placeholder: 'Введи Email', type: 'email', grid: '1 / 13' },
//     { required: true, show: true, id: 'password', name: '', placeholder: 'Введи пароль', type: 'password', grid: '1 / 13' },
//   ],
//   data: {
//     email: '',
//     password: ''
//   },
//   submit: {
//     name: 'Войти'
//   }
// }

export const PROFILE_COMMON_INPUTS = (user: User.Dto) => ({
  inputs: [
    { required: true, show: true, id: 'name', placeholder: 'Введи ФИО', type: 'text', name: 'ФИО', grid: '1 / 5', disabled: false },
    { required: true, show: true, id: 'offer', placeholder: 'Введи ваше ФИО или ИП', type: 'text', name: 'Ваше ФИО или ИП для публичной оферты', grid: '5 / 9', disabled: false },
    { required: true, show: true, id: 'support', placeholder: 'Введи логин или ссылку', type: 'text', name: 'Логин TG-поддержки или ссылка на сторонний сервис', grid: '1 / 5', disabled: false },
    { required: true, show: true, id: 'phone', placeholder: '+7(___)___-__-__', type: 'phone', name: 'Номер телефона', grid: '5 / 9', mask: '+7(###)###-##-##', disabled: false },
    { required: true, show: true, id: 'notificationAmount', placeholder: 'Укажите сумму в рублях', type: 'text', name: 'Минимальная сумма на счету для уведомления', mask: '######', grid: '1 / 5', disabled: false },
  ],
  data: {
    name: user?.name,
    phone: user?.phone,
    offer: user?.offer,
    support: user?.support,
    notificationAmount: user?.notificationAmount,
  },
});

export const PROFILE_ACCESS_INPUTS = (user: User.Dto) => ({
  inputs: [
    { required: true, show: true, id: 'accessCode', placeholder: 'Перейдя по этой ссылке, можно попасть в твой аккаунт!', type: 'link', name: 'Сгенерированная ссылка', grid: '1 / 7', disabled: false },
  ],
  data: {
    accessCode: `${process.env.VUE_APP_URL}/auth/access/${user?.accessCode}`,
  },
});

// PAYMENTS

export const USER_YOOMONEY_INPUTS = (yoomoney: PaymentDetails.Yoomoney) => ({
  inputs: [
    { required: true, show: true, id: 'purse', placeholder: 'Введи кошелек', type: 'text', name: 'Кошелек получателя *', grid: '1 / 13', disabled: false },
    { required: true, show: true, id: 'secretKey', placeholder: 'Введи секретный ключ', type: 'text', name: 'Секретный ключ *', grid: '1 / 13', disabled: false },
    { required: false, show: true, id: 'link', placeholder: 'Ссылку нужно вставить в настройках в Yoomoney, чтобы мы могли обрабатывать платежи подписчиков', type: 'link', name: 'Ссылка для уведомлений', grid: '1 / 13', disabled: false },
  ],
  data: {
    purse: yoomoney?.purse,
    secretKey: yoomoney?.secretKey,
    description: `Обращаем ваше внимание, что платежная система берет комиссию за прием платежей.\n\n Более подробно возможные комиссии можешь изучить по ссылке:`,
    description_source: 'https://pro-yoomoney.ru/komissii/',
    link: 'https://crm.botpay.me/api/payment/yoomoney'
  },
});

export const USER_TINKOFF_INPUTS = (tinkoff: PaymentDetails.Tinkoff) => ({
  inputs: [
    { required: true, show: true, id: 'terminal', placeholder: 'Введи терминал', type: 'text', name: 'Терминал *', grid: '1 / 13', disabled: false },
    { required: true, show: true, id: 'password', placeholder: 'Введи пароль', type: 'text', name: 'Пароль *', grid: '1 / 13', disabled: false },
    { required: false, show: true, id: 'link', placeholder: 'Ссылку нужно вставить в настройках в Tinkoff, чтобы мы могли обрабатывать платежи подписчиков', type: 'link', name: 'Ссылка для уведомлений', grid: '1 / 13', disabled: false },
  ],
  data: {
    terminal: tinkoff?.terminal,
    password: tinkoff?.password,
    link: 'https://crm.botpay.me/api/payment/tinkoff'
  },
});

export const USER_ROBOKASSA_INPUTS = (robokassa: PaymentDetails.Robokassa) => ({
  inputs: [
    { required: true, show: true, id: 'identificator', placeholder: 'Введи идентификатор', type: 'text', name: 'Идентификатор *', grid: '1 / 13', disabled: false },
    { required: true, show: true, id: 'first_password', placeholder: 'Введи первый пароль', type: 'text', name: 'Пароль #1 *', grid: '1 / 13', disabled: false },
    { required: true, show: true, id: 'second_password', placeholder: 'Введи второй пароль', type: 'text', name: 'Пароль #2 *', grid: '1 / 13', disabled: false },
  ],
  data: {
    identificator: robokassa?.identificator,
    first_password: robokassa?.first_password,
    second_password: robokassa?.second_password,
  },
});

export const USER_PRODAMUS_INPUTS = (prodamus: PaymentDetails.Prodamus) => ({
  inputs: [
    { required: true, show: true, id: 'secretKey', placeholder: 'Введи секретный ключ', type: 'text', name: 'Секретный ключ *', grid: '1 / 13', disabled: false },
    { required: true, show: true, id: 'src', placeholder: 'Вставьте ссылку на платежную страницу', type: 'text', name: 'Ссылка на платежную страницу *', grid: '1 / 13', disabled: false },
    { required: false, show: true, id: 'link', placeholder: 'Ссылку нужно вставить в настройках в Prodamus, чтобы мы могли обрабатывать платежи подписчиков', type: 'link', name: 'Ссылка для уведомлений', grid: '1 / 13', disabled: false },
  ],
  data: {
    secretKey: prodamus?.secretKey,
    src: prodamus?.src,
    link: 'https://crm.botpay.me/api/payment/prodamus'
  },
});

export const PAYMENT_DETAILS = [
  { id: "yoomoney", name: "Yoomoney", icon: markRaw(YoomoneyIcon), data: null as dynamicsObject | null, inputs: USER_YOOMONEY_INPUTS, description: '' },
  { id: "tinkoff", name: "Tinkoff", icon: markRaw(TinkoffIcon), data: null as dynamicsObject | null, inputs: USER_TINKOFF_INPUTS, description: '* для юр. лиц' },
  { id: "botpay", name: "BotPay", icon: markRaw(BotpayIcon), data: null as dynamicsObject | null, inputs: null, description: '' },
  // { id: "robokassa", name: "Robokassa", icon: markRaw(RobokassaIcon), data: null as dynamicsObject | null, inputs: USER_ROBOKASSA_INPUTS, copies: [
  //   { name: 'Ссылка для уведомлений', src: 'https://crm.botpay.me/api/payment/robokassa' },
  // ] },
  // { id: "prodamus", name: "Prodamus", icon: markRaw(ProdamusIcon), data: null as dynamicsObject | null, inputs: USER_PRODAMUS_INPUTS },
]