import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, vModelDynamic as _vModelDynamic, withKeys as _withKeys, resolveDirective as _resolveDirective, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31ff5aaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "title fz12"
}
const _hoisted_2 = {
  key: 1,
  class: "input"
}
const _hoisted_3 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = ["type", "placeholder"]
const _hoisted_6 = ["value", "data-id", "placeholder"]
const _hoisted_7 = ["type", "placeholder"]
const _hoisted_8 = ["type", "placeholder"]
const _hoisted_9 = {
  key: 0,
  class: "drop"
}
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_switcher = _resolveComponent("switcher")!
  const _component_common_link = _resolveComponent("common-link")!
  const _component_down_arrow_icon = _resolveComponent("down-arrow-icon")!
  const _directive_mask = _resolveDirective("mask")!

  return (_ctx.input.type === 'switcher' && _ctx.input.show)
    ? (_openBlock(), _createBlock(_component_switcher, {
        key: 0,
        style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
        input: _ctx.input,
        data: _ctx.data,
        onToggleSwitcher: _ctx.toggleSwitcher
      }, null, 8, ["style", "input", "data", "onToggleSwitcher"]))
    : (_ctx.input.type === 'link' && _ctx.input.show)
      ? (_openBlock(), _createBlock(_component_common_link, {
          key: 1,
          style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
          input: _ctx.input,
          data: _ctx.data
        }, null, 8, ["style", "input", "data"]))
      : (_ctx.input.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "common-input",
            style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`)
          }, [
            (_ctx.input.name)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.input.name), 1))
              : _createCommentVNode("", true),
            (_ctx.input.disabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.data[_ctx.input.id]), 1))
              : (_ctx.input.type === 'checkbox')
                ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.data[_ctx.input.id]]
                    ]),
                    _createElementVNode("span", null, _toDisplayString(_ctx.input.placeholder), 1)
                  ]))
                : (_ctx.input.type === 'textarea')
                  ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                      key: 3,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                      class: _normalizeClass({ error: _ctx.input.error }),
                      placeholder: _ctx.input.placeholder
                    }, null, 10, _hoisted_4)), [
                      [_vModelText, _ctx.data[_ctx.input.id]]
                    ])
                  : (_ctx.input.mask)
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 4,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                        type: _ctx.input.type,
                        class: _normalizeClass({ error: _ctx.input.error }),
                        placeholder: _ctx.input.placeholder,
                        onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$emit('inputHandler')), ["enter"]))
                      }, null, 42, _hoisted_5)), [
                        [_directive_mask, _ctx.input.mask],
                        [_vModelDynamic, _ctx.data[_ctx.input.id]]
                      ])
                    : (_ctx.input.type.includes('date'))
                      ? (_openBlock(), _createElementBlock("input", {
                          key: 5,
                          type: "text",
                          value: _ctx.dateFilter(_ctx.data[_ctx.input.id], _ctx.input.type),
                          "data-id": _ctx.input.id,
                          class: _normalizeClass({ error: _ctx.input.error }),
                          placeholder: _ctx.input.placeholder,
                          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.showCalendar && _ctx.showCalendar(...args)))
                        }, null, 10, _hoisted_6))
                      : (_ctx.input.drop)
                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 6,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                            class: _normalizeClass(["dropping", { active: _ctx.input.showDrop, error: _ctx.input.error }]),
                            type: _ctx.input.type,
                            placeholder: _ctx.input.placeholder,
                            onKeyup: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('inputKeyUp', _ctx.input))),
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('toggleDrop', _ctx.input)))
                          }, null, 42, _hoisted_7)), [
                            [_vModelDynamic, _ctx.data[_ctx.input.id]]
                          ])
                        : _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 7,
                            type: _ctx.input.type,
                            class: _normalizeClass({ error: _ctx.input.error }),
                            placeholder: _ctx.input.placeholder,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                            onKeyup: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.$emit('inputHandler')), ["enter"]))
                          }, null, 42, _hoisted_8)), [
                            [_vModelDynamic, _ctx.data[_ctx.input.id]]
                          ]),
            (_ctx.input.drop)
              ? (_openBlock(), _createBlock(_component_down_arrow_icon, {
                  key: 8,
                  class: _normalizeClass({ active: _ctx.input.showDrop }),
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('toggleDrop', _ctx.input)))
                }, null, 8, ["class"]))
              : _createCommentVNode("", true),
            _createVNode(_Transition, { name: "fade" }, {
              default: _withCtx(() => [
                (_ctx.input.drop && _ctx.input.showDrop)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.input.drop_data, (element) => {
                          return (_openBlock(), _createElementBlock("li", {
                            onClick: ($event: any) => (_ctx.$emit('selectDropElement', element, _ctx.input)),
                            key: element._id,
                            clas: "flex items-center"
                          }, _toDisplayString(_ctx.dropElementName(element)), 9, _hoisted_10))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ], 4))
        : _createCommentVNode("", true)
}