
import { defineAsyncComponent, defineComponent, PropType } from "vue-demi";
import { mask } from "vue-the-mask";
import { Modal } from "@/interfaces/modal/modal.dto";
import { DownArrowIcon } from '@/plugins/icons';
import { CalendarDates } from "@/interfaces/common/calendar";
import dateFilter from "@/filters/date.filter";
import { dynamicsObject } from "@/interfaces";

const Switcher = defineAsyncComponent(() => import('./switcher/index.vue'));
const CommonLink = defineAsyncComponent(() => import('./link/index.vue'));

export default defineComponent({
  emits: ['inputHandler', 'toggleDrop', 'selectDropElement', 'toggleSwitcher', 'inputKeyUp'],
  name: 'CommonInputs',
  directives: {
    mask
  },
  props: {
    input: {
      type: Object as PropType<Modal.Input>,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      calendarOptions: {
        id: `common-${this.input.id}`,
        target: `[data-id="${this.input.id}"]`,
        position: { top: 0, left: 0 },
        type: 'SINGLE'
      },
    }
  },
  mounted() {
    this.onEmits();
  },
  unmounted() {
    this.offEmits();
  },
  methods: {
    dateFilter,
    showCalendar() {
      const setup = {
        options: this.calendarOptions,
        dates: { startedAt: this.data[this.input.id] },
      };
      this.$store.commit("createCalendar", setup);
    },
    dropElementName(element: dynamicsObject) {
      return element.name;
    },
    toggleSwitcher() {
      this.$emit('toggleSwitcher', this.data[this.input.id], this.input.id);
    },
    // Emitter
    onEmits() {
      this.emitter.on(this.calendarOptions.id, this.actionTermsEmitter);
    },
    offEmits() {
      this.emitter.off(this.calendarOptions.id, this.actionTermsEmitter);
    },
    actionTermsEmitter({ dates }: { dates: CalendarDates }) {
      this.data[this.input.id] = dates.startedAt;
    },
  },
  components: {
    DownArrowIcon,
    Switcher,
    CommonLink
  }
});
